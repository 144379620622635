import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";

import ReactPlayer from "react-player";

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videos = [
    "videos/1/output_2400k_dash.mpd",
    "videos/2/output_2400k_dash.mpd",
    "https://bitmovin-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd",
    "http://dash.edgesuite.net/envivio/EnvivioDash3/manifest.mpd",
    "https://dash.akamaized.net/dash264/TestCasesUHD/2b/11/MultiRate.mpd",
  ];
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const switchVideo = (url: string) => {
    setCurrentVideo(url);
  };
  const [played, setPlayed] = useState(0);
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [hasLoadedFirstVideo, setHasLoadedFirstVideo] = useState(false);

  const ref = (player: ReactPlayer) => {
    setPlayer(player);
  };

  return (
    <div className="App">
      Video:
      <div>
        <ReactPlayer
          ref={ref}
          playing={isPlaying}
          url={currentVideo}
          onProgress={(state) => {
            console.log("Played seconds:", state.playedSeconds);
            setPlayed(state.playedSeconds);
          }}
          onPlay={() => {
            player?.seekTo(played, "seconds");
          }}
        />
      </div>
      <button
        onClick={() => {
          setIsPlaying(!isPlaying);
        }}
      >
        Play/Pause
      </button>
      {videos.map((video, index) => {
        return (
          <button
            onClick={() => {
              switchVideo(video);
            }}
          >
            POV {index + 1}
          </button>
        );
      })}
    </div>
  );
}

export default App;
